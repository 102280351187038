import styled from 'styled-components'
import { handleFontSize, handleFontWeight } from 'styles/typography'

export const ResumeExampleWrapper = styled.div`
  position: sticky;
  top: 20px;
  border-radius: 5px;
  background: ${({ theme }) => theme.background.secondary};
  width: 100%;
  max-width: 700px;

  #anon-company {
    cursor: pointer;
    padding: 2px 4px;
    margin: 2px;
    background-color: ${({ theme }) => theme.lightRed}33;
    border-bottom: 2px solid ${({ theme }) => theme.lightRed};
    font-weight: bold;
  }

  #anon-name {
    cursor: pointer;
    padding: 2px 4px;
    margin: 2px;
    background-color: ${({ theme }) => theme.orange}33;
    border-bottom: 2px solid ${({ theme }) => theme.orange};
    font-weight: bold;
  }

  #anon-years {
    cursor: pointer;
    padding: 2px 4px;
    margin: 2px;
    background-color: ${({ theme }) => theme.highlight}33;
    border-bottom: 2px solid ${({ theme }) => theme.highlight};
    font-weight: bold;
  }

  #anon-education {
    cursor: pointer;
    padding: 2px 4px;
    margin: 2px;
    background-color: ${({ theme }) => theme.highlight}33;
    border-bottom: 2px solid ${({ theme }) => theme.green};
    font-weight: bold;
  }
`

export const ResumeExampleContent = styled.div`
  border-radius: 5px;
  padding: 60px;
  position: relative;
  overflow: hidden;
`

export const ResumeExampleSectionListItem = styled.li`
  color: ${({ theme }) => theme.primary};
  font-size: ${() => handleFontSize('lg')};
  margin-left: 15px;
`

export const ResumeExampleScrollHintWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
`
