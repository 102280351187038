import styled from 'styled-components'

export const ResumeExampleScrollProgressWrapper = styled.div<{
  $progress?: number
}>`
  position: absolute;
  width: ${({ $progress }) =>
    $progress > 100 ? 'calc(100% - 10px)' : `calc(${$progress}% - 10px)`};
  height: 5px;
  background: ${({ theme }) => theme.highlight};
  border-radius: 20px;
  z-index: 100;
  left: 5px;
  top: 5px;
`
