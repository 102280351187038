import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useLayoutEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import _isUndefined from 'lodash-es/isUndefined'
export interface FadeProps {
  delay?: number
  duration?: number
  children?: React.ReactNode | string
  fadeKey?: any
  replayEffect?: boolean
  replaySwitchTimeout?: number
  fadeOutVerticalDistance?: number
  noFadeOut?: boolean
}

/**
 * Fade wrapper
 * @param children
 * @param delay - second
 * @param duration - second
 * @param fadeOutVerticalDistance - pixel
 * @param fadeKey - Reanimates when key is changed
 * @param replayEffect - play the fadeIn effect if true
 * @param replaySwitchTimeout - milliseconds to hide before fade in again
 * @param noFadeOut - No fade out effect after fade in
 * @returns
 */

export const Fade: React.FC<FadeProps> = ({
  delay = 0,
  duration = 0.3,
  fadeKey = '',
  fadeOutVerticalDistance = 10,
  children,
  replayEffect = undefined,
  replaySwitchTimeout = 300,
  noFadeOut = false,
}) => {
  const controls = useAnimation()
  const { ref: animateRef, inView } = useInView()

  useLayoutEffect(() => {
    if (!fadeKey) {
      if (inView) {
        controls.start('fadeIn')
      }
      if (!inView && !noFadeOut) {
        controls.start('fadedOut')
      }
    }
  }, [fadeKey, controls, inView])

  useLayoutEffect(() => {
    if (_isUndefined(replayEffect)) return
    if (!replayEffect) return

    controls.start('hidden')
    setTimeout(() => controls.start('fadeIn'), replaySwitchTimeout)
  }, [replayEffect, replaySwitchTimeout])

  const boxVariants = {
    fadedOut: {
      y: fadeOutVerticalDistance,
      opacity: 0,
      transition: {
        delay: delay,
        duration: duration,
      },
    },
    fadeIn: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: duration,
      },
    },
    hidden: {
      y: fadeOutVerticalDistance,
      opacity: 0,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={fadeKey}
        ref={animateRef}
        initial="fadedOut"
        animate={fadeKey ? 'fadeIn' : controls}
        variants={boxVariants}
        style={{ width: 'inherit' }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}
