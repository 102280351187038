import styled from 'styled-components'
import { transition1 } from 'styles/transformation'
import { SwiperSlide } from 'swiper/react'

export const CarouselArrowWrapper = styled.div<{
  next?: boolean
}>`
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px !important;
  color: ${({ theme }) => theme.primary} !important;
  background: ${({ next, theme }) =>
    next
      ? `linear-gradient(to left, ${theme.background.primary}, transparent)`
      : `linear-gradient(to right, ${theme.background.primary}, transparent)`};
  top: 0px;
  left: ${({ next }) => (next ? 'unset' : '5px')};
  right: ${({ next }) => (next ? '5px' : 'unset')};
  height: calc(100% - 25px);
  width: 100px;
  transform: unset;
  z-index: 10;
  overflow: hidden;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 10px;
    ${transition1}
  }

  :hover {
    background: ${({ next, theme }) =>
      next
        ? `linear-gradient(to left, ${theme.background.primary}, transparent)`
        : `linear-gradient(to right, ${theme.background.primary}, transparent)`};

    svg {
      height: 30px;
      width: 20px;
    }
  }
`

export const CarouselWrapper = styled.div<{
  $hideScrollbarBottomBorder?: boolean
  $centerSlides?: boolean
}>`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;

  .swiper-horizontal > .swiper-scrollbar {
    height: ${({ $hideScrollbarBottomBorder }) =>
      $hideScrollbarBottomBorder ? '0px' : '2px'} !important;
  }

  .swiper {
    width: 100%;
    padding-bottom: 20px;
  }

  .swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
  }

  .swiper-slide {
    flex-shrink: 0;
    display: block;
    max-height: 100%;
    height: auto;
  }

  .swiper-horizontal > .swiper-scrollbar {
    height: 2px;
    background-color: ${({ theme }) => theme.background.tertiary};
  }

  .swiper-scrollbar-drag {
    background-color: ${({ theme }) => theme.highlight};
  }
`

export const CarouselFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  gap: 10px;
`

export const SwiperSlidePreview = styled.div<{
  selected?: boolean
}>`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 2px solid
    ${({ theme, selected }) => (selected ? theme.highlight : theme.border)};
  overflow: hidden;
  opacity: ${({ selected }) => (selected ? '1' : '0.5')};

  > * {
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.secondary};
  }
`

export const CarouselSlideContent = styled.div<{
  $centerSlides?: boolean
}>`
  opacity: ${({ $centerSlides, theme }) => {
    if (theme.is_light_mode && !$centerSlides) {
      return '0.5'
    }
    if ($centerSlides) {
      return '1'
    }
    return '0.2'
  }};
  transform: ${({ $centerSlides }) =>
    $centerSlides ? 'scale(1)' : 'scale(0.7)'};
  margin: ${({ $centerSlides }) => ($centerSlides ? '0px' : '0px -70px')};
  border-radius: 5px;
  box-shadow: ${({ $centerSlides, theme }) => {
    if (theme.is_light_mode && $centerSlides) {
      return '5px 5px 20px rgba(0,0,0,0.1)'
    }
    if ($centerSlides) {
      return '20px 20px 100px rgba(0,0,0,0.3)'
    }
    return 'none'
  }};
  cursor: grab;
`
