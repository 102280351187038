import { motion } from 'framer-motion'
import { HiChevronDoubleDown } from 'react-icons/hi'
import { useTheme } from 'styled-components'
import { ResumeExampleScrollHintContent } from './styles'

const bounceTransition = {
  y: {
    duration: 0.5,
    repeat: Infinity,
    ease: 'easeOut',
  },
}

export const ResumeExampleScrollHint = () => {
  const theme = useTheme()

  return (
    <ResumeExampleScrollHintContent>
      <motion.div transition={bounceTransition} animate={{ y: [0, 10, 0] }}>
        <HiChevronDoubleDown size={20} color={theme.primary} />
      </motion.div>
    </ResumeExampleScrollHintContent>
  )
}
