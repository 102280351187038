import { ResumeExampleScrollProgressWrapper } from './styles'

export const ResumeExampleScrollProgress = ({
  currentScrollY,
  start,
  height,
}) => {
  const getScrollProgress = () => {
    return start > 0
      ? ((((currentScrollY - start) * 100) / height) as number)
      : 0
  }

  return <ResumeExampleScrollProgressWrapper $progress={getScrollProgress()} />
}
