import styled from 'styled-components'

export const ResumeExampleScrollHintContent = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: auto;
`
