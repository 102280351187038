import { Animation } from 'components/Animation'
import { ANIMATION_VARIANTS } from 'components/Animation/types'
import { Fade } from 'components/Fade'
import { FintrosLogo } from 'components/FintrosLogo'
import { Text } from 'components/Text'
import {
  ResumeAnonymizerContent,
  ResumeAnonymizerContentWrapper,
  ResumeAnonymizerWrapper,
} from './styles'

interface ResumeExampleAnonymizerProps {
  currentScrollY: number
  resumeOffsetTop: number
}

const anonymizerOptions = [
  { label: 'Removing name bias...', color: 'orange' },
  { label: 'Removing instances of education...', color: 'green' },
  { label: 'Masking company names...', color: 'lightRed' },
  { label: 'Masking years...', color: 'highlight' },
]

export const ResumeExampleAnonymizer: React.FC<
  ResumeExampleAnonymizerProps
> = ({ currentScrollY, resumeOffsetTop }) => {
  const ANON_BREAKPOINT = resumeOffsetTop / 2
  const RESUME_HEIGHT = 5000
  const START_ANONYMIZER_CONTENT = resumeOffsetTop + RESUME_HEIGHT / 2
  const END_ANONYMIZER_CONTENT = resumeOffsetTop + (RESUME_HEIGHT - 1000)

  const showAnonymizerContent =
    currentScrollY > START_ANONYMIZER_CONTENT &&
    currentScrollY < END_ANONYMIZER_CONTENT

  // Used to drop/raise anonymizer overlay
  const getTopValue = () => {
    if (currentScrollY > resumeOffsetTop && currentScrollY < ANON_BREAKPOINT) {
      return `calc(100% - ${(resumeOffsetTop - currentScrollY) * -1}px)`
    }
    if (currentScrollY > ANON_BREAKPOINT - 5) {
      return `calc(100% - ${currentScrollY - resumeOffsetTop}px)`
    }
    return '100%'
  }

  // Checks if current scroll position is in between a divided range
  const isBetweenAnonymizerRange = (rangeIndex) => {
    const range = (END_ANONYMIZER_CONTENT - START_ANONYMIZER_CONTENT) / 4
    const isPastAnonymizerStart =
      currentScrollY >= START_ANONYMIZER_CONTENT + range * rangeIndex
    const isBeforeAnonymizerEnd =
      currentScrollY <= START_ANONYMIZER_CONTENT + range * (rangeIndex + 1)

    return (isPastAnonymizerStart && isBeforeAnonymizerEnd) as boolean
  }

  const currentAnonymizerOption = anonymizerOptions.find((option, index) =>
    isBetweenAnonymizerRange(index)
  )

  return (
    <ResumeAnonymizerWrapper $top={getTopValue()}>
      <ResumeAnonymizerContentWrapper>
        {showAnonymizerContent && (
          <Fade fadeKey={showAnonymizerContent}>
            <ResumeAnonymizerContent>
              <FintrosLogo size={40} noColor iconOnly />
              <Text size="4xl">{currentAnonymizerOption.label}</Text>
              <Animation
                size={40}
                variant={ANIMATION_VARIANTS.loading}
                color={currentAnonymizerOption.color}
              />
            </ResumeAnonymizerContent>
          </Fade>
        )}
      </ResumeAnonymizerContentWrapper>
    </ResumeAnonymizerWrapper>
  )
}
