/*

  To wrap text with one of the tags, use a span tag directly in any 
  of the strings with one of the following corresponding ids:

  1. anon-company
  2. anon-name
  3. anon-education
  4. anon-years

*/

export const EXAMPLE_RESUMES = [
  {
    // Non-Anon
    id: 1,
    name: 'Steve Jobs',
    title: 'Apple Founder',
    email: 'stevejobs@gmail.com',
    image:
      'https://cdn.profoto.com/cdn/053149e/contentassets/d39349344d004f9b8963df1551f24bf4/profoto-albert-watson-steve-jobs-pinned-image-original.jpg?width=1280&quality=75&format=jpg',
    // Anon
    anon_name: '<span id="anon-name">Candidate #012318</span>',
    anon_email: '012318@fintros.com',
    workExperience: [
      {
        // Non-Anon
        title: 'Pixar Animation Studio',
        subtitle: 'CEO / Investor',
        years: '1989 - 1995',
        bulletPoints: [
          'Turned a small animation studio division into an industry powerhouse',
          'Led and funded the company during the making of Toy Story',
        ],
        // Anon
        anon_title: '<span id="anon-company">Large Animation Studio</span>',
        anon_subtitle: 'CEO / Investor',
        anon_years: '<span id="anon-years">6 years</span>',
        anon_bulletPoints: [
          'Turned a small animation studio division into an industry powerhouse',
          'Led and funded the company during the making of an <span id="anon-name">large acclaimed movie</span>',
        ],
      },
      {
        // Non-Anon
        title: 'Apple',
        subtitle: 'CEO / Co-Founder',
        years: '1979 - 2011',
        bulletPoints: [
          'Built Apple into the second largest computer manufacturer',
          'Led the development of products like the iPhone, iMac, iPod, and iPad',
          'Led one of the biggest IPOs in history',
        ],
        // Anon
        anon_title: '<span id="anon-company">FAAMG Company</span>',
        anon_subtitle: 'CEO / Co-Founder',
        anon_years: '<span id="anon-years">32 years</span>',
        anon_bulletPoints: [
          'Built <span id="anon-company">FAAMG company</span> into the second largest computer manufacturer',
          'Led the development of <span id="anon-name">large, influencial products</span>',
          'Led one of the biggest IPOs in history',
        ],
      },
    ],
    education: {
      // Non-Anon
      title: 'Reed College',
      years: '1972',
      bulletPoints: ['Dropped out after a semester'],
      // Anon
      anon_title: '<span id="anon-education">Liberal Arts College</span>',
      anon_years: '<span id="anon-years">1 year</span>',
      anon_bulletPoints: ['Dropped out after a semester'],
    },
  },

  {
    // Non-Anon
    id: 2,
    name: 'Elon Musk',
    title: 'Tesla / Space-Ex CEO',
    email: 'elonmusk@fintros.com',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/1200px-Elon_Musk_Royal_Society_%28crop2%29.jpg',
    // Anon
    anon_name: '<span id="anon-name">Candidate #1341993</span>',
    anon_email: '1341993@fintros.com',
    workExperience: [
      {
        // Non-Anon
        title: 'Tesla',
        subtitle: 'CEO',
        years: '2004 - Present',
        bulletPoints: [
          'Led the company to become the most valuable car manufacturer in the USA',
          'Increased Tesla sales by 50% in 2019, raising over $226 million',
        ],
        // Anon
        anon_title: '<span id="anon-company">Large Car Manufacturer</span>',
        anon_subtitle: 'CEO / Investor',
        anon_years: '<span id="anon-years">19 years</span>',
        anon_bulletPoints: [
          'Led the company to become the most valuable car manufacturer in the USA',
          'Increased the <span id="anon-company">companies</span> sales by 50% in 2019, raising over $226 million',
        ],
      },
      {
        // Non-Anon
        title: 'Space-Ex',
        subtitle: 'CEO / Founder',
        years: '2002 - Present',
        bulletPoints: [
          'Successfully test launched the Falcon Heavy Rocket, the highest capacity rocket in the world',
          'Negotiated a key contract with NASA to handle cargo transportation to the ISS',
        ],
        // Anon
        anon_title: '<span id="anon-company">Large Rocket Manufacturer</span>',
        anon_subtitle: 'CEO / Founder',
        anon_years: '<span id="anon-years">21 years</span>',
        anon_bulletPoints: [
          'Successfully test launched the highest capacity rocket in the world',
          'Negotiated a key contract with a <span id="anon-name">large government agency</span> to handle cargo transportation to the ISS',
        ],
      },
    ],
    education: {
      // Non-Anon
      title: 'University of Pennsylvania',
      years: '1992-1997',
      bulletPoints: [
        'Bachelor of Arts degree in physics and a Bachelor of Science degree in economics from the Wharton School',
      ],
      // Anon
      anon_title:
        '<span id="anon-education">Ivy League School in the USA</span>',
      anon_years: '<span id="anon-years">5 years</span>',
      anon_bulletPoints: [
        'Bachelor of Arts degree in physics and a Bachelor of Science degree in economics',
      ],
    },
  },

  {
    // Non-Anon
    id: 3,
    name: 'Jamie Dimon',
    title: 'CEO of JPMorgan Chase',
    email: 'jamiedimon@fintros.com',
    image:
      'https://media.licdn.com/dms/image/C4D03AQFvg6D5s1Y45Q/profile-displayphoto-shrink_400_400/0/1589887696276?e=1680134400&v=beta&t=8G7j-Qo_RshqVVFoano9zEQoidsBaeio4CYY_Fh43WQ',
    // Anon
    anon_name: '<span id="anon-name">Candidate #9182732</span>',
    anon_email: '9182732@fintros.com',
    workExperience: [
      {
        // Non-Anon
        title: 'JPMorgan Chase',
        subtitle: 'Chairman and CEO',
        years: '2004 - Present',
        bulletPoints: [
          'Runs the largest bank in the U.S. in terms of assets',
          'Unloaded $12 billion of subprime mortgages in 2006 buffered his bank against the 2008 crash',
        ],
        // Anon
        anon_title: '<span id="anon-company">Large US Bank</span>',
        anon_subtitle: 'Chairman and CEO',
        anon_years: '<span id="anon-years">19 years</span>',
        anon_bulletPoints: [
          'Runs the largest bank in the U.S. in terms of assets',
          'Unloaded $12 billion of subprime mortgages in 2006 buffered his bank against the 2008 crash',
        ],
      },
    ],
    education: {
      // Non-Anon
      title: 'Harvard Business School',
      years: '1978-1982',
      bulletPoints: [
        'During the summer at Harvard, he worked at Goldman Sachs. He graduated in 1982, earning an MBA as a Baker Scholar',
      ],
      // Anon
      anon_title:
        '<span id="anon-education">Ivy League Business School in the USA</span>',
      anon_years: '<span id="anon-years">4 years</span>',
      anon_bulletPoints: [
        'During the summer at <span id="anon-education">the school</span>, he worked at a <span id="anon-company">large US bank</span>. He graduated in 1982, earning an MBA as a Baker Scholar',
      ],
    },
  },
]

export const ANON_TOOLTIPS = {
  company: {
    color: 'lightRed',
    text: 'Fintros hides every instance of company names',
    title: 'COMPANY MASKING',
  },
  name: {
    color: 'orange',
    text: 'Fintros hides every instance of names',
    title: 'NAME MASKING',
  },
  education: {
    color: 'green',
    text: 'Fintros hides every instance of education institutes',
    title: 'EDUCATION MASKING',
  },
  years: {
    color: 'highlight',
    text: 'Fintros hides every instance of years',
    title: 'YEAR MASKING',
  },
}
