import { Fade } from 'components/Fade'
import { Text } from 'components/Text'
import { ResumeExamplePageProps } from 'containers/ResumeExamplePage/types'
import { useEffect, useRef, useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useTheme } from 'styled-components'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { ResumeExampleAnonymizer } from './components/ResumeExampleAnonymizer'
import { ResumeExamplePopover } from './components/ResumeExamplePopover'
import { ResumeExampleScrollHint } from './components/ResumeExampleScrollHint'
import { ResumeExampleScrollProgress } from './components/ResumeExampleScrollProgress'
import { ResumeExampleSection } from './components/ResumeExampleSection'
import {
  ResumeExampleContent,
  ResumeExampleScrollHintWrapper,
  ResumeExampleWrapper,
} from './styles'

interface ResumeExampleProps extends ResumeExamplePageProps {
  offsetTopTrigger?: number
}

export const ResumeExample: React.FC<ResumeExampleProps> = ({
  currentScrollY,
  currentResume,
  offsetTopTrigger = 1000,
}) => {
  const [showScrollHint, setShowScrollHint] = useState(false)
  const [resumeOffsetTop, setResumeOffsetTop] = useState(0)
  const theme = useTheme()
  const resumeExampleWrapperRef = useRef(null)
  const RESUME_HEIGHT = 5000
  const ANON_BREAKPOINT = resumeOffsetTop + RESUME_HEIGHT / 2
  const anonymous = resumeOffsetTop > 0 && currentScrollY > ANON_BREAKPOINT

  // Sets the starting point where the container div begins
  useEffect(() => {
    /*
      A bit hacky, but the issue here is the top position of the ref is wrong on load since multiple elements require 
      the user to scroll and fade them in to get their height. So the code below waits until a breakpoint I set is reached then 
      gets the top position.
    */
    if (
      resumeExampleWrapperRef.current.offsetTop > offsetTopTrigger &&
      resumeOffsetTop < offsetTopTrigger
    ) {
      setResumeOffsetTop(resumeExampleWrapperRef.current.offsetTop)
    }
  }, [currentScrollY, resumeExampleWrapperRef])

  // Shows hint text to keep scrolling
  useEffect(() => {
    if (currentScrollY > resumeOffsetTop && currentScrollY) {
      const showScrollHintTimer = setTimeout(
        () => setShowScrollHint(true),
        2000
      )
      return () => {
        clearTimeout(showScrollHintTimer)
        setShowScrollHint(false)
      }
    }
  }, [currentScrollY])

  return (
    <Wrapper
      fullWidth
      display="flex"
      alignItems="center"
      flexDirection="column"
      spacing={60}
      height={RESUME_HEIGHT + 2000}
    >
      <Fade>
        <Wrapper
          display="flex"
          alignItems="center"
          flexDirection="column"
          spacing={10}
        >
          <Text size="2xl">Introducing the Fintros JEDI Resume&#8482;</Text>
          <Wrapper display="flex" alignItems="center" spacing={5} horizontal>
            <Text size="xl" weight="light" color="secondary">
              Scroll to anonymize resume
            </Text>
            <MdOutlineKeyboardArrowDown color={theme.secondary} />
          </Wrapper>
        </Wrapper>
      </Fade>
      <ResumeExampleWrapper ref={resumeExampleWrapperRef}>
        <ResumeExampleScrollProgress
          height={RESUME_HEIGHT}
          start={resumeOffsetTop}
          currentScrollY={currentScrollY}
        />
        <ResumeExampleContent>
          <ResumeExampleAnonymizer
            currentScrollY={currentScrollY}
            resumeOffsetTop={resumeOffsetTop}
          />
          <Fade fadeKey={anonymous.toString()}>
            <Wrapper
              fullWidth
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              spacing={5}
            >
              <ResumeExamplePopover hidePopover={!anonymous} category="name">
                <Text size="6xl" weight="bolder">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: anonymous
                        ? currentResume.anon_name
                        : currentResume.name,
                    }}
                  ></div>
                </Text>
              </ResumeExamplePopover>
              <Text size="xl" weight="light" color="secondary">
                {anonymous ? currentResume.anon_email : currentResume.email}
              </Text>
            </Wrapper>
            <Wrapper mt={40} display="flex" flexDirection="column" spacing={20}>
              <Text size="xl" color="highlight">
                Work Experience
              </Text>
              {currentResume.workExperience.map((workExperience, index) => (
                <ResumeExampleSection
                  key={`work-experience-${index}`}
                  anonymous={anonymous}
                  title={
                    anonymous ? workExperience.anon_title : workExperience.title
                  }
                  subtitle={
                    anonymous
                      ? workExperience.anon_subtitle
                      : workExperience.subtitle
                  }
                  years={
                    anonymous ? workExperience.anon_years : workExperience.years
                  }
                  bulletPoints={
                    anonymous
                      ? workExperience.anon_bulletPoints
                      : workExperience.bulletPoints
                  }
                />
              ))}
            </Wrapper>
            <Wrapper mt={20} display="flex" flexDirection="column" spacing={20}>
              <Text size="xl" color="highlight">
                Education
              </Text>
              <ResumeExampleSection
                type="education"
                anonymous={anonymous}
                title={
                  anonymous
                    ? currentResume.education.anon_title
                    : currentResume.education.title
                }
                subtitle={
                  anonymous
                    ? currentResume.education.anon_subtitle
                    : currentResume.education?.subtitle
                }
                years={
                  anonymous
                    ? currentResume.education.anon_years
                    : currentResume.education.years
                }
                bulletPoints={
                  anonymous
                    ? currentResume.education.anon_bulletPoints
                    : currentResume.education.bulletPoints
                }
              />
            </Wrapper>
          </Fade>
        </ResumeExampleContent>
        {showScrollHint && (
          <ResumeExampleScrollHintWrapper>
            <Fade fadeKey={showScrollHint}>
              <ResumeExampleScrollHint />
            </Fade>
          </ResumeExampleScrollHintWrapper>
        )}
      </ResumeExampleWrapper>
    </Wrapper>
  )
}
