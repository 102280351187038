import styled from 'styled-components'
import { handleFontSize, handleFontWeight } from 'styles/typography'

export const ResumeExampleScorecardWrapper = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.background.secondary};
  width: 100%;
  max-width: 700px;
  padding: 40px;
`

export const ResumeExampleBiasTag = styled.div<{
  $color: string
}>`
  width: fit-content;
  padding: 5px 15px;
  border-radius: 20px;
  background: ${({ theme, $color }) => theme[$color]}33;
  color: ${({ theme, $color }) => theme[$color]};
  font-weight: ${() => handleFontWeight('medium')};
  font-size: ${() => handleFontSize('lg')};
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
`
