import { Popover } from 'antd'
import { Text } from 'components/Text'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { ResumeExamplePopoverTitle } from './styles'
import { ANON_TOOLTIPS } from 'containers/ResumeExamplePage/constants'

interface ResumeExamplePopoverProps {
  hidePopover?: boolean
  category: string
  children: React.ReactNode
}

const PopoverContent = (category) => {
  const { color, title, text } = ANON_TOOLTIPS[category]

  return (
    <Wrapper spacing={10}>
      <ResumeExamplePopoverTitle color={color}>
        {title}
      </ResumeExamplePopoverTitle>
      <Text size="xl" weight="light">
        {text}
      </Text>
    </Wrapper>
  )
}

export const ResumeExamplePopover: React.FC<ResumeExamplePopoverProps> = ({
  hidePopover = false,
  category,
  children,
}) => {
  return hidePopover ? (
    <>{children}</>
  ) : (
    <Popover placement="top" content={PopoverContent(category)}>
      {children}
    </Popover>
  )
}
