import { Text } from 'components/Text'
import ReactHtmlParser from 'react-html-parser'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { ResumeExampleSectionListItem } from '../../styles'
import { ResumeExamplePopover } from 'components/ResumeExample/components/ResumeExamplePopover'

interface ResumeExampleSectionProps {
  anonymous?: boolean
  type?: string
  title?: string
  subtitle?: string
  years?: string
  bulletPoints?: any
}

export const ResumeExampleSection: React.FC<ResumeExampleSectionProps> = ({
  anonymous,
  type = 'work',
  title,
  subtitle,
  years,
  bulletPoints,
}) => {
  const renderBulletPoint = (bulletPoint) => {
    // Converts bullet point string to html, which can then be mapped and wrapped in a tooltip
    const html = ReactHtmlParser(bulletPoint)

    return (
      <>
        {html.map((element, index) => {
          if (element?.props) {
            return (
              <ResumeExamplePopover
                key={`bullet-point-popover-${index}`}
                hidePopover={false}
                category={element.props.id.slice(5)}
              >
                {element}
              </ResumeExamplePopover>
            )
          }
          return element
        })}
      </>
    )
  }

  return (
    <Wrapper mb={20}>
      <Wrapper fullWidth display="flex" justifyContent="space-between" mb={10}>
        <Wrapper>
          <ResumeExamplePopover
            hidePopover={!anonymous}
            category={type === 'work' ? 'company' : 'education'}
          >
            <Text size="xl">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Text>
          </ResumeExamplePopover>
          {subtitle && (
            <Text size="lg" weight="light" color="secondary" italics>
              {subtitle}
            </Text>
          )}
        </Wrapper>
        <ResumeExamplePopover hidePopover={!anonymous} category="years">
          <Text size="xl" weight="bold">
            <div dangerouslySetInnerHTML={{ __html: years }}></div>
          </Text>
        </ResumeExamplePopover>
      </Wrapper>
      <ul>
        {bulletPoints?.map((bulletPoint, index) => {
          return (
            <ResumeExampleSectionListItem key={`bullet-point-${index}`}>
              {renderBulletPoint(bulletPoint)}
            </ResumeExampleSectionListItem>
          )
        })}
      </ul>
    </Wrapper>
  )
}
