import styled from 'styled-components'

export const ResumeAnonymizerWrapper = styled.div.attrs((props) => ({
  style: {
    top: (props as any).$top,
  },
}))<{
  $top?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 5000px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 50;
  pointer-events: none;
  background: ${({ theme }) => `linear-gradient(
    to bottom, 
    rgba(255,255,255,0), 
    ${theme.background.secondary}, 
    ${theme.background.secondary},
    rgba(255,255,255,0)
  )`};
`

export const ResumeAnonymizerContentWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;
  pointer-events: none;
`

export const ResumeAnonymizerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 400px;
  height: 200px;
`
