import { Text } from 'components/Text'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { ResumeExampleBiasTag, ResumeExampleScorecardWrapper } from './styles'

const REMOVED_BIASES = [
  { score: 19, name: 'Employer Anonymization', color: 'red' },
  { score: 10, name: 'Name Masking', color: 'orange' },
  { score: 9, name: 'Date Masking', color: 'highlight' },
  { score: 5, name: 'Identification Masking', color: 'lightRed' },
  { score: 5, name: 'Education Anonymization', color: 'green' },
  { score: 3, name: 'Formatting Algorithm', color: 'red' },
]

export const ResumeExampleScorecard = () => {
  return (
    <ResumeExampleScorecardWrapper>
      <Wrapper display="flex" justifyContent="center" mb={40}>
        <Text size="xl" color="primary">
          51 Biases Removed
        </Text>
      </Wrapper>
      <Wrapper
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        horizontal
      >
        {REMOVED_BIASES.map((bias, index) => (
          <ResumeExampleBiasTag key={`bias-tag-${index}`} $color={bias.color}>
            <span>{bias.score}</span>
            {bias.name}
          </ResumeExampleBiasTag>
        ))}
      </Wrapper>
    </ResumeExampleScorecardWrapper>
  )
}
